import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {
    Button, CircularLoader, TextField,
    Typography,
    Accordion,
    IconButton, Alert,
} from "@sber-friend/flamingo-core";
import style from "./TokenProgramming.module.scss";
import {useEffectOnce} from "usehooks-ts";
import SuccessAlert from '../../../components/SuccessAlert/SuccessAlert';
import {getUserAgent} from '../../../helpers';
import {useGetHwpPublicKeyQuery, useRegisterTokenMutation} from "../../../api/devices";
import {ReactComponent as PDFIcon} from "../../../assets/img/icons/icon-pdf.svg";
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import {CloseIcon, CopyIcon} from "@sber-friend/flamingo-icons";
import {ListItem} from "@sber-friend/flamingo-components";


interface OwnProps {
}

type Props = OwnProps;

const TokenProgramming: FunctionComponent<Props> = (props) => {


    const userAgent = getUserAgent()


    // --- запросы

    const {
        data: hwpPublicKeyData,
        isLoading: hwpPublicKeyLoading,
        error: hwpPublicKeyError,
        refetch: hwpPublicKeyRefetch,
    } = useGetHwpPublicKeyQuery(undefined);

    const [fetchRegisterToken, {
        isSuccess: registerTokenSuccess,
        error: registerTokenError,
        isLoading: registerTokenLoading,
        reset: resetRegisterTokenData,
    }] = useRegisterTokenMutation();

    // ---


    const dataBlockField = useRef<HTMLInputElement>(null);


    const [accordionOpen, setAccordionOpen] = useState(false);


    const [dataBlock, setDataBlock] = useState("");
    const [otpCodeField, setOtpCodeField] = useState("");
    const [publickKeyCoppied, setPublickKeydCoppied] = useState(false)

    const conditionRegisterToken = (dataBlock.length > 0 && otpCodeField.length === 6);


    const copyTextToClipboard = async () => {
        try {
            if (hwpPublicKeyData?.result.key) {
                setPublickKeydCoppied(false)
                await navigator.clipboard.writeText(hwpPublicKeyData.result.key);
                setAccordionOpen(false)
                setPublickKeydCoppied(true)


                setTimeout(() => {
                    let scrollEl = document.querySelector("." + style.step2)

                    const elementPosition = scrollEl?.getBoundingClientRect().top;
                    window.scrollBy({
                        top: elementPosition,
                        behavior: 'smooth'
                    });
                    dataBlockField?.current?.focus()
                })
            }
        } catch (err) {
            console.error('Ошибка:', err);
        }
    };

    const refetchPublicKey = () => {
        hwpPublicKeyRefetch()
    }

    const clearDataBlock = () => {
        setDataBlock("")
    }

    const registerToken = async () => {

        // отправка метрик click stream
        sendEvent(
            {
                eventCategory: 'Программирование токена',
                eventAction: 'клик на кнопку Зарегистрировать',
                value: ''
            }
        )
        //

        const res = await fetchRegisterToken({
            data: dataBlock,
            otp: otpCodeField
        })

        if ("data" in res && res.data.status === "ok") {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Программирование токена',
                    eventAction: 'клик на кнопку Зарегистрировать/успешное программирование',
                    value: ''
                }
            )
            //
        }
    }

    const resetRegisterToken = () => {
        setDataBlock("")
        setOtpCodeField("")
        resetRegisterTokenData();
        try {
            setTimeout(() => {
                let scrollEl = document.querySelector("." + style.step2)

                const elementPosition = scrollEl?.getBoundingClientRect().top;
                window.scrollBy({
                    top: elementPosition,
                    behavior: 'smooth'
                });
                dataBlockField?.current?.focus()
            })
        } catch (e) {
            console.log(e)
        }

    }

    useEffect(()=>{
        if (registerTokenError){
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Программирование токена',
                    eventAction: 'клик на кнопку Зарегистрировать/неуспешное программирование',
                    value: `${registerTokenError?.code}: ${registerTokenError?.message}`,
                }
            )
            //
        }
    },[registerTokenError])

    const onMount = async () => {

    }


    useEffectOnce(() => {
        onMount()
    })

    return (
        <>
            {
                userAgent === "web" ? <div className={style.webWrap}>
                        <Typography variant={"h6"}>
                            Используйте программатор
                        </Typography>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            Зайдите на эту страницу с программатора через СберБраузер
                        </Typography>
                    </div>
                    : (hwpPublicKeyLoading ? (<div style={{height: 400}} className={"center"}>
                                <CircularLoader size={"large"}/>
                            </div>
                        )
                        : <div className={style.mobileWrap}>
                            <ListItem
                                className={style.pdfWrap}
                                icon={<PDFIcon/>}
                                key="with-icon-item-1"
                                primaryText="Инструкция по установке"
                                secondaryText="в формате PDF"
                                action={<Button href={"https://otp.sberbank.ru/instructions/token_programming.pdf"}
                                                target={"_blank"}
                                >
                                    Открыть
                                </Button>}
                            />
                            <Typography variant={"h5"}>
                                1. Скопируйте ключ безопасности
                            </Typography>
                            {
                                hwpPublicKeyError?.message ? <>
                                        <div className={style.alertWrap}>
                                            <Alert severity={"error"}>{hwpPublicKeyError?.message}</Alert>
                                        </div>
                                        <Button color="primary"
                                                fullWidth
                                                onClick={refetchPublicKey}
                                        >
                                            Попробовать снова
                                        </Button>
                                    </>
                                    : <>
                                        <Accordion title="Ключ безопасности"
                                                   expanded={accordionOpen}
                                                   onChange={() => setAccordionOpen(!accordionOpen)}
                                                   style={{borderBottom: "none"}}
                                        >
                                            <div className={style.accordionContent}>
                                                {/*<Typography variant={"body1"}>*/}
                                                {/*    ---- BEGIN PUBLIC KEY ----*/}
                                                {/*</Typography>*/}
                                                {/*<Typography variant={"body1"} className={style.publickKey}>*/}
                                                {/*    {hwpPublicKeyData?.result.key}*/}
                                                {/*</Typography>*/}
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    value={hwpPublicKeyData?.result.key}
                                                    readOnly
                                                    withoutBorders
                                                    rows={16}
                                                />
                                                {/*<Typography variant={"body1"}>*/}
                                                {/*    ----END PUBLIC KEY ----*/}
                                                {/*</Typography>*/}
                                                <Typography variant={"body1"} className={style.text}>
                                                    Нажмите «Копировать» и вставьте его в приложение ProgOTP
                                                </Typography>
                                                <Button color="primary" startIcon={<CopyIcon/>} fullWidth
                                                        onClick={copyTextToClipboard}>Копировать</Button>
                                            </div>
                                        </Accordion>
                                        {!accordionOpen && !publickKeyCoppied && <>
                                            <Typography variant={"body1"} className={style.description}>
                                                Нажмите «Копировать» и вставьте его в приложение ProgOTP
                                            </Typography>
                                            <Button color="primary"
                                                    startIcon={<CopyIcon/>}
                                                    fullWidth
                                                    onClick={copyTextToClipboard}
                                            >
                                                Копировать
                                            </Button>
                                        </>}
                                    </>
                            }

                            {publickKeyCoppied && !accordionOpen && <SuccessAlert>Ключ скопирован</SuccessAlert>}
                            {publickKeyCoppied && <>
                                <Typography variant={"h5"} className={style.step2}>
                                    2. Зарегистрируйте и проверьте токен
                                </Typography>
                                {
                                    registerTokenSuccess ? <>
                                            <div className={style.alertWrap}>
                                                <SuccessAlert>Токен зарегистрирован</SuccessAlert>
                                            </div>
                                            <Button color="primary"
                                                    fullWidth
                                                    onClick={resetRegisterToken}
                                            >
                                                Зарегистрировать следующий
                                            </Button>
                                        </>
                                        : <>
                                            <Typography variant={"body1"} className={style.mb16}>
                                                Скопируйте блок данных из приложения ProgOTP
                                            </Typography>
                                            <TextField placeholder={"OTM4NDEwMDIzIAowOTMwMT2..."}
                                                       fullWidth
                                                       inputRef={dataBlockField}
                                                       value={dataBlock}
                                                       onChange={(e) => setDataBlock(e.target.value)}
                                                       label={"Блок данных"}
                                                       required
                                                       endIcon={<IconButton
                                                           className={style.deleteTextIcon}
                                                           onClick={clearDataBlock}
                                                       >
                                                           <CloseIcon/>
                                                       </IconButton>}
                                            />
                                            <Typography variant={"body1"} className={style.mb16}>
                                                Введите ОТР-код токена. Если на шкале времени меньше 2 делений, дождитесь
                                                следующий.
                                            </Typography>
                                            <TextField placeholder={"148869"}
                                                       fullWidth
                                                       value={otpCodeField}
                                                       onChange={(e) => {
                                                           let newVal = e.target.value.replace(/[^0-9]/g, '')
                                                           if (newVal.length < 7)
                                                               setOtpCodeField(newVal)
                                                       }}
                                                       label={"OTP-код"}
                                                       customCounter={`${otpCodeField.length}/6`}
                                                       onKeyDown={(e) => {
                                                           if (e.key === "Enter" && otpCodeField.length === 6) {
                                                               registerToken()
                                                           }
                                                       }}
                                                       required
                                            />
                                            {registerTokenError?.message && <div className={style.alertWrap}>
                                                <Alert severity={"error"}>{registerTokenError?.message}</Alert>
                                            </div>}

                                            <Button color="primary"
                                                    disabled={!conditionRegisterToken}
                                                    fullWidth
                                                    onClick={registerToken}
                                                    loading={registerTokenLoading}
                                            >
                                                Зарегистрировать
                                            </Button>
                                        </>
                                }
                            </>}
                        </div>)
            }
        </>
    );
};

export default TokenProgramming;
