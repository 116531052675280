import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import {RootState} from "../../store";
import {clearSessionStorage} from "../jwt";
import {setSudirUser, setToken} from "../auth/authSlice";
import {setCookie} from "../../helpers";
import {ErrorBody, SuccessResponse} from "../common";

export const PORtAL_MESSAGE = "/sessions/portal_message.json";
export const DEVICE_ADD_ACCEPTED = "/devices/device_accepted.json";
export const DEVICES_LIST_PATH = "/devices/my_sessions.json";
export const ADD_VIP_DEVICE = "/devices/add_device_delegate.json";
export const VIP_USER_DEVICES_LIST = "/devices/user_devices.json";
export const VIP_USERS_LIST = "/sessions/vip_members.json";
export const MULTI_FACTOR_VERIFY = "/multifactor/verify";
export const GET_USER = "/get_user_role.json";
export const ADD_DEVICE_PATH = "/devices/add_device.json";
export const DELETE_DEVICE_PATH = "/devices/drop.json";
export const DELETE_ALL_DEVICES_PATH = "/devices/drop_all.json";
export const DELETE_VIP_DEVICES_PATH = "/devices/drop_vip.json";
export const LOG_OUT_SUDIR = "/openid-connect-auth/logout";
export const GET_HWP_PUBLIC_KEY = "/hwp/engineer/get_public_key";
export const IMPORT_HWP_HMAC_KEY = "/hwp/engineer/import_hmac_key";
export const ACTIVATE_HWOTP = "/hwp/user/activate_hwotp";
export const GET_HWP_SERIAL_LIST = "/hwp/user/serial_list";
export const DELETE_HWOTP = "/hwp/user/delete_hwotp";
export const SEND_REVIEW = "/friend/send_review";


export const MULTI_FACTOR_URL = "/multifactor/";


interface IPortalMessage {
    message: string
    value?: number
}

interface IUserRole {
    available_sections: ["pao_employee", "vip_support", "hwotp_admin", "hwotp_user"],
    ui_settings: { showTerms: boolean, multifactorEnabled: boolean },
    user_login: string
}

interface IMultiFactorVerify {
    verified: boolean;
    message: string;
}

interface AddVipUserDevicePayload {
    login: string
    time_code: string
}

export interface DeleteVipPayloadReq {
    "login": string;
    "devices": Array<{
        device_id: string;
        platform: string;
    }>;
    "pendingDevices": Array<string>
}

interface DeletePayloadReq {
    platform: string
    device_id: string
    status: boolean
}

export interface INewDevice {
    "device_id": string
    "platform": string
}

export interface IDevice {
    "device_id": string
    "platform": string
    "name": string
    "last_active_time": number | string
    "addedDevice": number
    "model": string
    "ip_address": string
    "expired_days": string
    "status": boolean
}

interface IGetDevicesRes {
    "maxDevices": string
    "sessions": Array<IDevice>
    "pendingDevices": Array<IDevice>

}

interface RegisterTokenPayload {
    "data": string
    "otp": string
}

interface ActivateTokenPayload {
    "otp": string
    "serial": string
    "pin": string
}

interface ISendReviewPayload {
    mark: number
    comment: string
    platform: string
}

export const dynamicBaseQuery: BaseQueryFn<string | FetchArgs,
    unknown, ErrorBody> = async (args, devicesApi, extraOptions) => {
    const baseUrl = (devicesApi.getState() as RootState).auth.baseUrl;
    const baseQuery = fetchBaseQuery({
        baseUrl, prepareHeaders: (headers, {getState}) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const token = (getState() as RootState).auth.token
            const validToken = (token && !JSON.parse(window.atob(token.split('.')[1]))?.pilot)

            if (validToken) {
                headers.set('authorization', `Bearer ${token}`)
            }
            headers.set('segment', 'sigma')
            headers.set("Content-Type", "application/json")
            headers.set("Accept", "application/json, text/plain, */*")
            return headers
        },
    });
    let result = await baseQuery(args, devicesApi, extraOptions);
    let errorData = result.data as {
        status: string
        error: {
            code: number,
            message: string
        },
    }
    if (errorData?.status === "error") {
        let newRes = {
            meta: result.meta,
            error: {
                code: errorData.error.code,
                message: errorData.error.message
            },
        }
        return newRes
    }
    if (result.meta?.response?.status === 401) {
        if ((devicesApi.getState() as RootState).auth.sudirUser) {
            await logOutSudir()
            devicesApi.dispatch(setSudirUser(false))
            setCookie('sudirUser', "")
        }
        devicesApi.dispatch(setToken(""))
        setCookie('logInTime', "")
        clearSessionStorage()
    }
    if (result.error) {
        return {
            error: {
                code: result.meta?.response?.status ? result.meta?.response?.status : result.error?.status,
                message: result.meta?.response?.statusText ? (result.meta?.response?.status + " " + result.meta.response.statusText)
                    : `Ошибка ${result.meta?.response?.status ? result.meta.response.status : result?.error?.status}`,
            }
        }
    }
    return result
};


export const devicesApi = createApi({
    reducerPath: 'devicesApi',
    baseQuery: dynamicBaseQuery,
    tagTypes: ['Devices', 'VipDevices', 'HWPToken'],
    endpoints: (builder) => ({
        sendPortalMessage: builder.mutation<SuccessResponse, IPortalMessage>({
            query: (payload) => ({
                url: PORtAL_MESSAGE,
                method: "POST",
                body: {
                    message: payload.message,
                    value: payload?.value,
                    time: new Date(),
                }
            }),
            invalidatesTags: ['VipDevices']
        }),
        getUserRole: builder.query<SuccessResponse<IUserRole>, undefined>({
            query: () => ({
                url: GET_USER,
                method: "POST",
            })
        }),
        deleteVipDevice: builder.mutation<SuccessResponse, DeleteVipPayloadReq>({
            query: (devices) => ({
                url: DELETE_VIP_DEVICES_PATH,
                method: "POST",
                body: devices
            }),
            invalidatesTags: ['VipDevices']
        }),
        deleteDevice: builder.mutation<SuccessResponse, DeletePayloadReq>({
            query: (device) => ({
                url: DELETE_DEVICE_PATH,
                method: "POST",
                body: {
                    platform: device.platform,
                    device_id: device.device_id,
                    is_pending: !!device?.status
                }
            }),
            invalidatesTags: ['Devices']
        }),
        deleteAllDevice: builder.mutation({
            query: (devices) => ({
                url: DELETE_ALL_DEVICES_PATH,
                method: "POST",
                body: devices
            }),
            invalidatesTags: ['Devices']
        }),
        multiFactorVerify: builder.mutation<SuccessResponse<IMultiFactorVerify>, Object>({
            query: (params) => ({
                url: MULTI_FACTOR_VERIFY,
                method: "POST",
                body: params
            })
        }),
        getMultiFactorUrl: builder.mutation<SuccessResponse<{ url: string }>, Object>({
            query: (params: string) => ({
                url: MULTI_FACTOR_URL + params,
                method: "POST",
            })
        }),
        getDevices: builder.query<SuccessResponse<IGetDevicesRes>, any>({
            query: () => ({
                url: DEVICES_LIST_PATH,
                method: "POST",
            }),
            providesTags: result => ['Devices']
        }),
        getVipDevices: builder.query<SuccessResponse<IGetDevicesRes>, string>({
            query: (account: string) => ({
                url: VIP_USER_DEVICES_LIST,
                method: "POST",
                body: {account},
            }),
            providesTags: result => ['VipDevices']
        }),
        getVipMembers: builder.query({
            query: (params) => ({
                url: VIP_USERS_LIST,
                params: {
                    query: params
                }
            }),
        }),
        addDevice: builder.mutation<SuccessResponse<IDevice>, string>({
            query: (code) => ({
                url: ADD_DEVICE_PATH,
                method: "POST",
                body: {time_code: code}
            }),
        }),
        isDeviceAccepted: builder.mutation<SuccessResponse<{}>, INewDevice>({
            query: (device) => ({
                url: DEVICE_ADD_ACCEPTED,
                method: "POST",
                body: device
            }),
            invalidatesTags: (result, error, arg) => {
                if (result?.result) {
                    return ['Devices']
                } else {
                    return []
                }
            },
        }),
        addVipDevice: builder.mutation<any, AddVipUserDevicePayload>({
            query: (payload) => ({
                url: ADD_VIP_DEVICE,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ['VipDevices']
        }),
        getHwpPublicKey: builder.query<SuccessResponse<{ key: string }>, undefined>({
            query: () => ({
                url: GET_HWP_PUBLIC_KEY,
            }),
        }),
        registerToken: builder.mutation<SuccessResponse, RegisterTokenPayload>({
            query: (payload) => ({
                url: IMPORT_HWP_HMAC_KEY,
                method: "POST",
                body: payload
            }),
        }),
        getHwpTokenList: builder.query<SuccessResponse<{ serial: Array<string> }>, undefined>({
            query: () => ({
                url: GET_HWP_SERIAL_LIST,
            }),
            providesTags: result => ['HWPToken']
        }),
        activateToken: builder.mutation<SuccessResponse, ActivateTokenPayload>({
            query: (payload) => ({
                url: ACTIVATE_HWOTP,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ['HWPToken']
        }),
        deleteHwpToken: builder.mutation<SuccessResponse, { "serial": string }>({
            query: (payload) => ({
                url: DELETE_HWOTP,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ['HWPToken']
        }),
        sendReview: builder.mutation<SuccessResponse, ISendReviewPayload>({
            query: (payload) => ({
                url: SEND_REVIEW,
                method: "POST",
                body: payload,
            }),
        }),
    }),
})

export async function logOutSudir() {
    try {
        const response = await fetch(LOG_OUT_SUDIR,
            {
                method: 'GET',
                redirect: 'manual',
            }
        )
        return response;
    } catch (error) {
        console.error("API -> getDevices -> error", error);
    }
}


export const {
    useSendPortalMessageMutation,
    useLazyGetVipDevicesQuery,
    useGetUserRoleQuery,
    useLazyGetUserRoleQuery,
    useGetVipMembersQuery,
    useGetDevicesQuery,
    useGetMultiFactorUrlMutation,
    useMultiFactorVerifyMutation,
    useIsDeviceAcceptedMutation,
    useAddDeviceMutation,
    useAddVipDeviceMutation,
    useDeleteAllDeviceMutation,
    useDeleteDeviceMutation,
    useDeleteVipDeviceMutation,
    useGetHwpTokenListQuery,
    useDeleteHwpTokenMutation,
    useActivateTokenMutation,
    useGetHwpPublicKeyQuery,
    useRegisterTokenMutation,
    useSendReviewMutation,
} = devicesApi;
