import {BaseQueryFn, FetchArgs, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

export type SuccessResponse<T = any> = {
    status: "ok";
    result: T;
};

export type ErrorResponse = {
    status: "error";
    error: ErrorBody
};

export interface ErrorBody {
    code: number | string;
    message: string;
}

export const baseQuery = (
    {baseUrl}: { baseUrl: string } = {baseUrl: ''}
): BaseQueryFn<string | FetchArgs,
    unknown, ErrorBody> => async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
        baseUrl, prepareHeaders: (headers, )=>{
            headers.set('segment','sigma')
            headers.set("Content-Type", "application/json")
            headers.set( "Accept", "application/json, text/plain, */*")
            return headers
        }
    });
    let result = await baseQuery(args, api, extraOptions);
    let errorData = result.data as {
        status: string
        error: {
            code: number,
            message: string
        },
    }
    if (errorData?.status === "error") {
        let newRes = {
            meta: result.meta,
            error: {
                code: errorData.error.code,
                message: errorData.error.message
            },
        }
        return newRes
    }
    if (result.error) {
        if (result?.error?.status === 'FETCH_ERROR' && !result.meta?.response){
            return {
                error: {
                    code: 525,
                    message: "Сертификат недействителен",
                }
            }
        }
        return {
            error: {
                code: result.meta?.response?.status ? result.meta?.response?.status : result.error?.status,
                message: result.meta?.response?.statusText ? result.meta.response.statusText : `Ошибка ${result.meta?.response?.status ? result.meta.response.status: result?.error?.status}`,
            }
        }
    }
    return result
};
