import React, {ChangeEvent, FunctionComponent, useEffect, useState} from 'react';
import style from "./Header.module.scss";
import {
    Button,
    Tabs,
    Tab,
    Typography,
    IconButton,
    Rating, TextField, Chip, ChipGroup, Avatar
} from "@sber-friend/flamingo-core";
import {routesPath} from "../../routes/const";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {setSudirUser, setToken} from "../../api/auth/authSlice";
import {setCookie} from "../../helpers";
import {clearSessionStorage} from "../../api/jwt";
import {logOutSudir, useSendReviewMutation} from "../../api/devices";
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import {useGetSegmentMutation} from "../../api/main";
import SidePanel from "../SidePanel/SidePanel";
import Feedback from '@sber-friend/flamingo-components/Feedback';
import {FeedbackServiceIcon, LogoOtp} from "@sber-friend/flamingo-icons";
import {ListItem} from "@sber-friend/flamingo-components";
import OtpIcon from "../../assets/img/icons/otp.png";
import Snackbar from "@sber-friend/flamingo-core/Snackbar";

interface OwnProps {
}

type Props = OwnProps;

const tabsValue = Object.values(routesPath).filter(e => (e !== routesPath.questions && e !== routesPath.sudirAuth))


const Header: FunctionComponent<Props> = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch()

    const [getSegment, {data: getSegmentData}] = useGetSegmentMutation({fixedCacheKey: 'shared-segment',})
    const [sendReview, {
        isLoading: sendReviewLoading,
        isSuccess: sendReviewSuccess,
        isError: sendReviewError,
        reset: sendReviewReset
    }] = useSendReviewMutation()

    const token = useAppSelector(state => state.auth.token)
    const sudirUser = useAppSelector(state => state.auth.sudirUser)

    const conditionSuccessAuth = (token || sudirUser);

    const [activePage, setActivePage] = useState('/');
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [comment, setComment] = useState("");

    const [labels, setLabels] = useState([
        {label: ' Быстро работает', isActive: false},
        {label: ' Интерфейс', isActive: false},
        {label: ' Функционал', isActive: false},
    ]);



    const handleChangeTab = (event: Object, newValue: string) => {
        if (newValue === routesPath.instructions) {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Клик на Инструкции',
                    eventAction: 'Header сайта',
                    value: ''
                }
            )
            //
        }
        if (newValue === routesPath.devices) {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Клик на Устройства',
                    eventAction: 'Header сайта',
                    value: ''
                }
            )
            //
        }
        if (newValue === routesPath.questions) {
            window.open('https://sberfriend.sbrf.ru/sberfriend/#/application/97C2E1A8DE5B93ACE054022128574717')
        } else {
            navigate(newValue)
        }
    }

    const handleOpenSidePanel = () => {
        setFeedbackOpen(true)
    }

    const handleCloseSidePanel = () => {
        setFeedbackOpen(false)
    }

    const handleChangeRating = (event: ChangeEvent<{}>, newValue: number | null) => {
        setValue(Number(newValue));
    };

    const handleSendReview = async () => {
        const comm = labels.filter(e => e.isActive).map(e => e.label) + " " + comment
        const payload = {
            mark: value,
            comment: comm.trim(),
            platform: "WEB"
        }
        sendReview(payload)
    }


    const setActiveHandler = (labelName: string) => {
        const newLabels = labels.map((item) => {
            if (item.label === labelName) {
                return {...item, isActive: !item.isActive};
            }
            return item;
        });
        setLabels(newLabels);
    };

    const handleLogOut = async () => {
        if (sudirUser) {
            await logOutSudir()
            setCookie('sudirUser', '')
        }
        setCookie('logInTime', "")
        dispatch(setToken(""))
        clearSessionStorage()
        dispatch(setSudirUser(false))
        if (getSegmentData?.result.sigma) {
            navigate(routesPath.main)
        }
    }

    const handleLogIn = () => {
        navigate(routesPath.devices)
    }

    useEffect(() => {
        if (tabsValue.find(el => el.includes(location.pathname))) {
            setActivePage(location.pathname)
        }
    }, [location]);


    useEffect(() => {
        if (sudirUser && !getSegmentData) {
            getSegment(undefined)
        }
    }, [sudirUser, getSegment, getSegmentData])

    useEffect(()=>{
        if (!conditionSuccessAuth){
            handleCloseSidePanel()
        }
    },[conditionSuccessAuth])

    return (
        <div className="container">
            <header className={style.header}>
                <LogoOtp/>
                <div className={style.actions}>
                    {conditionSuccessAuth && <IconButton onClick={handleOpenSidePanel}><FeedbackServiceIcon/></IconButton>}
                    {(location.pathname !== routesPath.devices || conditionSuccessAuth) &&
                        <Button variant="text" onClick={token || sudirUser ? handleLogOut : handleLogIn}>
                            <Typography variant="body1"
                                        className={"green-link"}>{conditionSuccessAuth ? "Выйти" : "Войти"}</Typography>
                        </Button>}
                </div>
            </header>
            <Tabs
                value={activePage}
                styleVariant="lined"
                indicatorColor="primary"
                onChange={handleChangeTab}
            >
                <Tab label="Главная" value={routesPath.main}/>
                <Tab label="Инструкции" value={routesPath.instructions}/>
                <Tab label="Устройства" value={routesPath.devices}/>
                <Tab label="Вопросы" value={routesPath.questions}/>
            </Tabs>
            <SidePanel open={feedbackOpen} onOpen={handleOpenSidePanel} onClose={handleCloseSidePanel}
                       header={"Оценка сервиса"}>
                <Feedback
                    header={<ListItem
                        key="default-item-1"
                        primaryText={<Typography variant='body1'>OTP</Typography>}
                        secondaryText={<Typography variant='body1'>Безопасный доступ к продуктам Сбера</Typography>}
                        paddingDense
                        avatar={
                            <Avatar
                                src={OtpIcon}
                                size={"medium"}
                                letters="OTP"
                                alt="OTP"
                                color="primary"
                                variant="rounded"
                            />
                        }
                    />
                    }
                    rating={
                        <Rating
                            disabled={sendReviewLoading}
                            value={value}
                            size="large"
                            name="demo-rating"
                            onChange={handleChangeRating}
                        />
                    }
                    chips={<ChipGroup label="Что понравилось">
                        {labels.map((label, index) => {
                            return (
                                <Chip
                                    disabled={sendReviewLoading}
                                    key={index}
                                    label={label.label}
                                    id={label.label}
                                    color={label.isActive ? 'primary' : 'default'}
                                    onClick={() => setActiveHandler(label.label)}
                                    textEllipsis
                                />
                            );
                        })}
                    </ChipGroup>
                    }
                    textField={<TextField fullWidth multiline minRows={4} label="Комментарий"
                                          disabled={sendReviewLoading}
                                          limitedSymbols={1200}
                                          value={comment}
                                          onChange={(e) => setComment(e.target.value)}
                                          placeholder={"Поделитесь впечатлениями"} margin='none'/>}
                />
                <div className={style.sidePanelActions}>
                    <Button color={"primary"} onClick={handleSendReview} disabled={!value} loading={sendReviewLoading}>Отправить</Button>
                    <Button onClick={handleCloseSidePanel}>Закрыть</Button>
                </div>
            </SidePanel>
            <Snackbar
                style={{position: 'fixed'}}
                placement={"top"}
                variant={sendReviewError ? "error" : "default"}
                open={sendReviewSuccess || sendReviewError}
                iconButtonClose
                transitionDuration={300}
                TransitionProps={{}}
                autoHideDuration={7000}
                onClose={() => {
                    sendReviewReset()
                }}
                message={(sendReviewSuccess && "Оценка успешно отправлена") || (sendReviewError && "Произошла ошибка отправки оценки")}
            />
        </div>
    );
};

export default Header;
