import React, { FunctionComponent } from 'react';
import {Route, Routes} from "react-router-dom";
import {MainPage} from "../pages/MainPage/MainPage";
import {routesPath} from "./const";
import InstructionsPage from "../pages/InstructuinsPage/InstructionsPage";
import Devices from "../pages/DevicesPage/Devices";
import SudirAuthPage from "../pages/SudirAuthPage/SudirAuthPage";

interface OwnProps {}

type Props = OwnProps;

const RoutesApp: FunctionComponent<Props> = (props) => {

  return (
      <Routes>
          <Route path={routesPath.main} element={<MainPage/>}/>
          <Route path={routesPath.devices} element={<Devices/>}/>
          <Route path={routesPath.instructions} element={<InstructionsPage/>}/>
          <Route path={routesPath.sudirAuth} element={<SudirAuthPage/>}/>
      </Routes>
  );
};

export default RoutesApp;
